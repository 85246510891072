import React from 'react';

const Step3Business = ({ toggleClick }) => {
  return (
    <section className="stack stack--large centered stretch phm" id="step-3">
      <div className="live-quote__header capped row row-end mbl">
        <button className="live-quote__close" onClick={toggleClick}>
          <div className="is-hidden-visually">Close</div>
        </button>
      </div>
      <div className="stack">
        <h1 className="heading-base heading-large heading-heavy heading-liberator">
          Thank you!
        </h1>
        <p className="medium medium--res">
          <em>An Account Executive will be reaching out to you soon.</em>
        </p>
        <p className="phs">
          <small>
            Check out other ways to get inspired through University Tees
          </small>
        </p>
        <div className="capped-narrow grid-2_1">
          <div>
            <a
              className="button button--full stretch"
              href="/products?product_filter%5Bproduct_collection_ids%5D%5B%5D=33#products"
            >
              Browse Apparel
            </a>
          </div>
          <div>
            <a
              className="button button--full stretch"
              href="/products?product_filter%5Bdivision_ids%5D%5B%5D=17&product_filter%5Bsearch%5D=#products"
            >
              View Promo Products
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Step3Business;
