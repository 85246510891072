import React from 'react';

const Step2College = ({
  value,
  onChange,
  onSubmit,
  backButton,
  toggleClick,
  schools,
}) => {
  return (
    <section className="stack stack--large stretch phm" id="step-2">
      <div className="live-quote__header capped row row-pushed mbl">
        <button onClick={backButton} className="live-quote__back">
          <span className="is-hidden-visually">Back</span>
        </button>
        <div>
          <strong>Step 2 of 3</strong>
        </div>
        <button className="live-quote__close" onClick={toggleClick}>
          <div className="is-hidden-visually">Close</div>
        </button>
      </div>
      <div className="stack centered">
        <h1 className="heading-base heading-large heading-heavy heading-liberator">
          Give us the deets
        </h1>
        <p className="medium medium--res">
          <em>Let's make something great together.</em>
        </p>
      </div>

      <form
        className="live-quote-animated capped-narrow stack"
        onSubmit={e => {
          e.preventDefault();
          onSubmit();
        }}
      >
        <div className="grid-2_1">
          <div className="stack stack--small">
            <label className="input-label" htmlFor="name">
              Name*
            </label>
            <input
              className="field"
              type="text"
              id="name"
              value={value.name}
              required={true}
              placeholder="Ex: Johnny Appleseed"
              onChange={e => {
                onChange({ name: e.target.value });
              }}
            />
          </div>

          <div className="stack stack--small">
            <label className="input-label" htmlFor="email">
              Email*
            </label>
            <input
              className="field"
              type="email"
              id="email"
              required={true}
              placeholder="Ex: email@address.com"
              value={value.email}
              onChange={e => {
                onChange({ email: e.target.value });
              }}
            />
          </div>

          <div className="stack stack--small">
            <label className="input-label" htmlFor="phone">
              Phone Number*
            </label>
            <input
              className="field"
              type="tel"
              id="phone"
              required={true}
              value={value.phone}
              placeholder="Ex: 555-555-5555"
              onChange={e => {
                onChange({ phone: e.target.value });
              }}
            />
          </div>

          <div className="stack stack--small">
            <label className="input-label" htmlFor="school">
              School*
            </label>
            <select
              className="field"
              type="text"
              id="school"
              required={true}
              value={value.school}
              onChange={e => {
                onChange({ school: e.target.value });
              }}
              onBlur={e => {
                onChange({ school: e.target.value });
              }}
            >
              {schools.map(school => (
                <option key={school} value={school}>
                  {school}
                </option>
              ))}
            </select>
          </div>

          <div className="stack stack--small">
            <label className="input-label" htmlFor="quantity">
              Estimated Quantity
            </label>
            <input
              className="field"
              type="text"
              id="quantity"
              required={true}
              value={value.quantity}
              placeholder="Ex: 100"
              onChange={e => {
                onChange({ quantity: e.target.value });
              }}
            />
          </div>

          <div className="stack stack--small">
            <label className="input-label" htmlFor="delivery-date">
              Delivery Date
            </label>
            <input
              className="field field--date"
              type="date"
              id="delivery-date"
              placeholder="MM/DD/YYYY"
              value={value.deliveryDate}
              onChange={e => {
                onChange({ deliveryDate: e.target.value });
              }}
            />
          </div>

          <div className="stack stack--small">
            <label className="input-label" htmlFor="budget">
              Budget
            </label>
            <input
              className="field"
              type="text"
              id="budget"
              value={value.budget}
              placeholder="Ex: $1250"
              onChange={e => {
                onChange({ budget: e.target.value });
              }}
            />
          </div>

          <div>
            <p className="input-label">Preferred Contact Method</p>
            <div className="row phm">
              <div className="col-4--m row row-gap-small">
                <input
                  type="radio"
                  id="contact-call"
                  value="call"
                  checked={value.contactMethod === 'call'}
                  onChange={e => {
                    onChange({ contactMethod: 'call' });
                  }}
                />
                <label className="input-label" htmlFor="contact-call">
                  Call
                </label>
              </div>
              <div className="col-4--m row row-gap-small">
                <input
                  type="radio"
                  id="contact-email"
                  value="email"
                  checked={value.contactMethod === 'email'}
                  onChange={e => {
                    onChange({ contactMethod: 'email' });
                  }}
                />
                <label className="input-label" htmlFor="contact-email">
                  Email
                </label>
              </div>

              <div className="col-4--m row row-gap-small">
                <input
                  type="radio"
                  id="contact-text"
                  value="text"
                  checked={value.contactMethod === 'text'}
                  onChange={e => {
                    onChange({ contactMethod: 'text' });
                  }}
                />
                <label className="input-label" htmlFor="contact-text">
                  Text
                </label>
              </div>
            </div>
          </div>
        </div>

        <div className="centered mtl">
          <button className="button">One More Step</button>
        </div>
      </form>
    </section>
  );
};

export default Step2College;
