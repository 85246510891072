import React from 'react';

const Step3College = ({
  value,
  onChange,
  onSubmit,
  backButton,
  toggleClick,
}) => {
  return (
    <section className="stack stack--large stretch phm" id="step-3">
      <div className="live-quote__header capped row row-pushed mbl">
        <button onClick={backButton} className="live-quote__back">
          <span className="is-hidden-visually">Back</span>
        </button>
        <div>
          <strong>Step 3 of 3</strong>
        </div>
        <button className="live-quote__close" onClick={toggleClick}>
          <div className="is-hidden-visually">Close</div>
        </button>
      </div>
      <div className="stack centered">
        <h1 className="heading-base heading-large heading-heavy heading-liberator">
          Almost finished.
        </h1>
        <p className="medium medium--res">
          <em>Give us more detail. Not sure? Skip to submit.</em>
        </p>
      </div>
      <form
        className="live-quote-animated capped-narrow stack"
        onSubmit={e => {
          e.preventDefault();
          onSubmit();
        }}
      >
        <div className="stack stack--small">
          <label className="input-label" htmlFor="organization">
            Organization
          </label>
          <input
            className="field"
            type="text"
            id="organization"
            required={true}
            value={value.organization}
            placeholder="Ex: Chi Omega, Nursing"
            onChange={e => {
              onChange({ organization: e.target.value });
            }}
          />
        </div>

        <div className="grid-2_1">
          <div className="stack stack--small">
            <label className="input-label" htmlFor="design-code">
              Original Design Code
              <a
                className="mls teal"
                target="_blank"
                rel="noreferrer noopener"
                href="/designs"
              >
                Browse designs
              </a>
            </label>
            <input
              className="field"
              type="text"
              id="design-code"
              value={value.designCode}
              placeholder="Ex: UT123"
              onChange={e => {
                onChange({ designCode: e.target.value });
              }}
            />
          </div>

          <div className="stack stack--small">
            <label className="input-label" htmlFor="product-type">
              Product Type
              <a
                className="mls teal"
                target="_blank"
                rel="noreferrer noopener"
                href="/products"
              >
                Find products
              </a>
            </label>
            <input
              className="field"
              type="text"
              id="product-type"
              value={value.productType}
              placeholder="Ex: Gildan 5000"
              onChange={e => {
                onChange({ productType: e.target.value });
              }}
            />
          </div>
        </div>

        <div className="stack stack--small">
          <label className="input-label" htmlFor="details">
            How did you hear about us?
          </label>
          <textarea
            className="field"
            id="details"
            value={value.howDidYouHearAboutUs}
            required={true}
            placeholder="Let us know...."
            onChange={e => {
              onChange({ howDidYouHearAboutUs: e.target.value });
            }}
          />
        </div>

        <div className="stack stack--small">
          <label className="input-label" htmlFor="details">
            Additional Details
          </label>
          <textarea
            className="field"
            id="details"
            value={value.additionalDetails}
            placeholder="Additional details..."
            onChange={e => {
              onChange({ additionalDetails: e.target.value });
            }}
          />
        </div>

        <div className="centered mtl">
          <button className="button">Submit Quote Request Now</button>
        </div>
      </form>
    </section>
  );
};

export default Step3College;
