import React from 'react';
import IconShipped from '../../images/redesign-images/icons/icon-shipped.svg';
import IconShirt from '../../images/redesign-images/icons/icon-shirt.svg';

const Step1College = ({ onSubmit, backButton, toggleClick }) => {
  return (
    <section className="stack stack--large stretch phm" id="step-1">
      <div className="live-quote__header capped row row-pushed mbl">
        <button onClick={backButton} className="live-quote__back">
          <span className="is-hidden-visually">Back</span>
        </button>
        <div>
          <strong>Step 1 of 3</strong>
        </div>
        <button className="live-quote__close" onClick={toggleClick}>
          <span className="is-hidden-visually">Close</span>
        </button>
      </div>
      <div className="stack centered">
        <h1 className="heading-base heading-large heading-heavy heading-liberator">
          What are you looking for?
        </h1>
        <p className="medium medium--res">
          <em>Please choose one</em>
        </p>
      </div>

      <form
        className="live-quote-animated capped-narrow stack"
        onSubmit={e => {
          e.preventDefault();
          onSubmit();
        }}
      >
        <div className="grid-2_1 grid-gap-large">
          <div className="centered">
            <div>
              <img src={IconShipped} alt="" className="quote-xp-icon" />
            </div>
            <div>
              <button className="button">I need merch for my group</button>
              <p className="mts">
                We can help your group create amazing custom apparel and
                promotional products.
                <a
                  href="/how-group-ordering-works"
                  title="Get info on how to setup a group order with UTees."
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <span className="is-hidden-visually">
                    Get info on how to setup a group order with UTees.
                  </span>
                  <i className="icon-info-link mlxs"></i>
                </a>
              </p>
            </div>
          </div>

          <div className="centered">
            <div>
              <img src={IconShirt} alt="" className="quote-xp-icon" />
            </div>
            <div>
              <a
                href="https://shop.universitytees.com"
                className="button"
                target="_blank"
                rel="noopener noreferrer"
              >
                I want to shop for myself
              </a>
              <p className="mts">
                Shop single-piece styles on our online retail store, Shop UTees.
              </p>
            </div>
          </div>
        </div>
      </form>
    </section>
  );
};

export default Step1College;
