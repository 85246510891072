import React from 'react';

const Step0 = ({ onChange, toggleClick }) => {
  return (
    <section className="stack stack--large phm" id="step-0">
      <div className="live-quote__header capped row row-end">
        <button className="live-quote__close" onClick={toggleClick}>
          <div className="is-hidden-visually">Close</div>
        </button>
      </div>
      <div className="stack centered">
        <h1 className="heading-base heading-large heading-heavy heading-liberator">
          Start a group order
        </h1>
        <p className="medium medium--res">
          <em>Which group best represents you?</em>
        </p>
      </div>

      <div className="live-quote-animated capped-narrow grid-3_1 phl--xs">
        <div>
          <button
            className="button button--full stretch"
            type="button"
            onClick={() => onChange('college')}
          >
            College
          </button>
        </div>
        <div>
          <button
            className="button button--full stretch phl--xs"
            type="button"
            onClick={() => onChange('business')}
          >
            Business
          </button>
        </div>
        <div>
          <button
            className="button button--full stretch phl--xs"
            type="button"
            onClick={() => onChange('other')}
          >
            Other
          </button>
        </div>
      </div>
    </section>
  );
};

export default Step0;
