import React, { useState, useEffect } from 'react';
import snakecase from 'lodash.snakecase';
import mapKeys from 'lodash.mapkeys';
import { createPortal } from 'react-dom';
import { post } from '../store';
import reCAPTCHABase from '../reCAPTCHABase';
import Step0 from './QuoteApp/Step0';
import Step1College from './QuoteApp/Step1College';
import Step2College from './QuoteApp/Step2College';
import Step3College from './QuoteApp/Step3College';
import Step4College from './QuoteApp/Step4College';
import Step1Business from './QuoteApp/Step1Business';
import Step2Business from './QuoteApp/Step2Business';
import Step3Business from './QuoteApp/Step3Business';
import Step1Other from './QuoteApp/Step1Other';
import Step2Other from './QuoteApp/Step2Other';

const modalRoot = document.querySelector('#quote-modal');
const bodyEl = document.querySelector('.site-wrap');

const QuoteApp = ({
  schools,
  buttonClasses,
  buttonText = 'Start a Group Order',
}) => {
  const initialFieldState = {
    salesStream: '',
    name: '',
    email: '',
    phone: '',
    school: schools[0],
    organization: '',
    business: '',
    quantity: '',
    deliveryDate: '',
    budget: '',
    contactMethod: '',
    designCode: '',
    productType: '',
    additionalDetails: '',
    howDidYouHearAboutUs: '',
  };

  const [isOpen, setIsOpen] = useState(true);

  const [currentStepNumber, setCurrentStepNumber] = useState(0);

  const [quote, setQuote] = useState(initialFieldState);

  const toggleClick = () => {
    // Toggle the open/close state of the form.
    setIsOpen(v => !v);
    // Start from the first step when the form is closed.
    setCurrentStepNumber(0);
    // Clear the form fields in this step as well.
    setQuote(initialFieldState);
  };

  const queryParam = window.location.search;

  const submitQuote = () => {
    const quote_form = mapKeys(quote, (_, key) => snakecase(key));
    const payload = { quote_form };

    reCAPTCHABase('quote_form').then(p => post('/quote', { ...payload, ...p }));
  };

  useEffect(() => {
    if (queryParam !== '?college=true') {
      return;
    } else {
      setCurrentStepNumber(1);
    }
  }, [queryParam]);

  // Prevent body element from scrolling when modal is open
  useEffect(() => {
    if (isOpen) {
      bodyEl.classList.add('contain-content');
    } else {
      bodyEl.classList.remove('contain-content');
    }
    return () => {
      bodyEl.classList.remove('contain-content');
    };
  }, [isOpen]);

  // Dismiss form by hitting escape
  useEffect(() => {
    const handleKeyUp = e => {
      if (e.key === 'Escape') setIsOpen(false);
    };
    document.addEventListener('keyup', handleKeyUp);
    return () => {
      document.removeEventListener('keyup', handleKeyUp);
    };
  }, []);

  const steps = [
    <Step0
      toggleClick={toggleClick}
      onChange={g => {
        setQuote(q => ({ ...q, salesStream: g }));

        if (g === 'college') {
          setCurrentStepNumber(1);
        } else if (g === 'business') {
          setCurrentStepNumber(5);
        } else {
          setCurrentStepNumber(8);
        }
      }}
    />,
    <Step1College
      backButton={() => {
        setCurrentStepNumber(0);
      }}
      toggleClick={toggleClick}
      onSubmit={() => {
        setCurrentStepNumber(2);
      }}
    />,
    <Step2College
      backButton={() => {
        setCurrentStepNumber(1);
      }}
      toggleClick={toggleClick}
      onChange={q => setQuote(oq => ({ ...oq, ...q }))}
      value={quote}
      onSubmit={() => {
        setCurrentStepNumber(3);
      }}
      schools={schools}
    />,
    <Step3College
      backButton={() => {
        setCurrentStepNumber(2);
      }}
      toggleClick={toggleClick}
      onChange={q => setQuote(oq => ({ ...oq, ...q }))}
      value={quote}
      onSubmit={() => {
        submitQuote();
        setCurrentStepNumber(4);
      }}
    />,
    <Step4College toggleClick={toggleClick} />,
    <Step1Business
      backButton={() => {
        setCurrentStepNumber(0);
      }}
      toggleClick={toggleClick}
      onChange={q => setQuote(oq => ({ ...oq, ...q }))}
      value={quote}
      onSubmit={() => {
        setCurrentStepNumber(6);
      }}
    />,
    <Step2Business
      backButton={() => {
        setCurrentStepNumber(5);
      }}
      toggleClick={toggleClick}
      onChange={q => setQuote(oq => ({ ...oq, ...q }))}
      value={quote}
      onSubmit={() => {
        submitQuote();
        setCurrentStepNumber(7);
      }}
    />,
    <Step3Business toggleClick={toggleClick} />,
    <Step1Other
      backButton={() => {
        setCurrentStepNumber(0);
      }}
      toggleClick={toggleClick}
      onChange={q => setQuote(oq => ({ ...oq, ...q }))}
      value={quote}
      onSubmit={() => {
        submitQuote();
        setCurrentStepNumber(9);
      }}
    />,
    <Step2Other toggleClick={toggleClick} />,
  ];

  const currentStep = steps[currentStepNumber];

  return (
    <>
      <button className={buttonClasses} onClick={toggleClick}>
        {buttonText}
      </button>
      {isOpen &&
        createPortal(
          <div className="live-quote-wrapper">
            <div className="live-quote">
              <div className="live-quote__inner">{currentStep}</div>
            </div>
          </div>,
          modalRoot
        )}
    </>
  );
};

export default QuoteApp;
