import React from 'react';

const Step1Other = ({ value, onChange, onSubmit, backButton, toggleClick }) => {
  return (
    <section className="stack stack--large stretch phm" id="step-1">
      <div className="live-quote__header capped row row-pushed mbl">
        <button onClick={backButton} className="live-quote__back">
          <span className="is-hidden-visually">Back</span>
        </button>
        <div>
          <strong>Step 1 of 1</strong>
        </div>
        <button className="live-quote__close" onClick={toggleClick}>
          <span className="is-hidden-visually">Close</span>
        </button>
      </div>
      <div className="stack centered">
        <h1 className="heading-base heading-large heading-heavy heading-liberator">
          Give us the deets
        </h1>
        <p className="medium medium--res">
          <em>Let's make something great together.</em>
        </p>
      </div>

      <form
        className="live-quote-animated capped-narrow stack"
        onSubmit={e => {
          e.preventDefault();
          onSubmit();
        }}
      >
        <div className="stack stack--small">
          <label className="input-label" htmlFor="name">
            Name*
          </label>
          <input
            className="field"
            type="text"
            id="name"
            value={value.name}
            required={true}
            placeholder="Ex: Johnny Appleseed"
            onChange={e => {
              onChange({ name: e.target.value });
            }}
          />
        </div>

        <div className="stack stack--small">
          <label className="input-label" htmlFor="email">
            Email*
          </label>
          <input
            className="field"
            type="email"
            id="email"
            required={true}
            placeholder="Ex: email@address.com"
            value={value.email}
            onChange={e => {
              onChange({ email: e.target.value });
            }}
          />
        </div>

        <div className="stack stack--small">
          <label className="input-label" htmlFor="phone">
            Phone Number*
          </label>
          <input
            className="field"
            type="tel"
            id="phone"
            required={true}
            value={value.phone}
            placeholder="Ex: 555-555-5555"
            onChange={e => {
              onChange({ phone: e.target.value });
            }}
          />
        </div>

        <div className="stack stack--small">
          <label className="input-label" htmlFor="details">
            How did you hear about us?
          </label>
          <textarea
            className="field"
            id="details"
            value={value.howDidYouHearAboutUs}
            placeholder="Let us know...."
            onChange={e => {
              onChange({ howDidYouHearAboutUs: e.target.value });
            }}
          />
        </div>

        <div className="stack stack--small">
          <label className="input-label" htmlFor="details">
            Additional Details
          </label>
          <textarea
            className="field"
            id="details"
            value={value.additionalDetails}
            placeholder="Additional details..."
            onChange={e => {
              onChange({ additionalDetails: e.target.value });
            }}
          />
        </div>

        <div className="centered mtl">
          <button className="button">Submit Quote Request Now</button>
        </div>
      </form>
    </section>
  );
};

export default Step1Other;
