import React from 'react';

const Step2Business = ({
  value,
  onChange,
  onSubmit,
  backButton,
  toggleClick,
}) => {
  return (
    <section className="stack stack--large stretch phm" id="step-2">
      <div className="live-quote__header capped row row-pushed mbl">
        <button onClick={backButton} className="live-quote__back">
          <span className="is-hidden-visually">Back</span>
        </button>
        <div>
          <strong>Step 2 of 2</strong>
        </div>
        <button className="live-quote__close" onClick={toggleClick}>
          <span className="is-hidden-visually">Close</span>
        </button>
      </div>
      <div className="stack centered">
        <h1 className="heading-base heading-large heading-heavy heading-liberator">
          Almost finished.
        </h1>
        <p className="medium medium--res">
          <em>Let's make something great together.</em>
        </p>
      </div>
      <form
        className="live-quote-animated capped-narrow stack"
        onSubmit={e => {
          e.preventDefault();
          onSubmit();
        }}
      >
        <div className="stack stack--small">
          <label className="input-label" htmlFor="details">
            How did you hear about us?
          </label>
          <textarea
            className="field"
            id="details"
            value={value.howDidYouHearAboutUs}
            placeholder="Let us know...."
            onChange={e => {
              onChange({ howDidYouHearAboutUs: e.target.value });
            }}
          />
        </div>

        <div className="stack stack--small">
          <label className="input-label" htmlFor="details">
            Additional Details
          </label>
          <textarea
            className="field"
            id="details"
            value={value.additionalDetails}
            required={true}
            placeholder="Additional details..."
            onChange={e => {
              onChange({ additionalDetails: e.target.value });
            }}
          />
        </div>

        <div className="centered mtl">
          <button className="button">Submit Quote Request Now</button>
        </div>
      </form>
    </section>
  );
};

export default Step2Business;
